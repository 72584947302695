import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../../components/elements/Button"
import Card from "../../../../components/elements/Card"
import TextInput from "../../../../components/elements/Inputs/TextInput"
import AccountNavigation from "../../Navigation"
import axios from "axios";
import { getUserToken } from "../../../../services/auth"
import LoadingSpinner from "../../../../components/elements/LoadingSpinner"
import { API_BASE } from "../../../../spectory-config"
import { renderDate } from "../../../../functions/date"

const Addresses = (props) => {

    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        var config = {
            method: 'post',
            url: `${API_BASE}/customer/addresses/get`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                token: getUserToken()
            }
        };


        axios(config)
            .then(function (response) {
                console.log("response", response.data.data)

                setAddresses(response.data.data)

                setLoading(false);
            })
            .catch(function (error) {
                console.log("response err", error)
                setLoading(false);
            });

    }, [])


    return (
        <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
            <div className="col4">
                <AccountNavigation current={'konto/addresses'} />
            </div><div className="col8">
                <div>
                    <h4>Adressen</h4>
                    <div className="container fluid" style={{ padding: 0 }}>
                    <div className="col6" style={{ padding: 0 }}>
                            <div>
                                <h4>Rechnungsadresse</h4>
                                {
                                    addresses.billing === undefined ? <LoadingSpinner type="container" active={true} />
                                        :
                                        <>
                                            <Button
                                                type="secondary"
                                                destination="/konto/addresses/billing"
                                                title={addresses.billing['first_name'] !== '' ? addresses.billing['first_name'] + ' ' + addresses.billing['last_name'] : 'Wurde noch nicht angelegt'}
                                            />
                                            <br />
                                            <br />
                                            <Link to="/konto/addresses/billing" style={{ color: '#ADADAD' }}>{addresses.billing !== undefined ? 'Bearbeiten' : 'Hinzufügen'}</Link>
                                        </>
                                }

                            </div>
                        </div>
                        <div className="col6" style={{ padding: 0 }}>
                            <div>
                                <h4>Lieferadresse</h4>
                                {
                                    addresses.shipping === undefined ? <LoadingSpinner type="container" active={true} />
                                        :
                                        <>
                                            <Button
                                                type="secondary"
                                                destination="/konto/addresses/shipping"
                                                title={addresses.shipping['first_name'] !== '' ? addresses.shipping['first_name'] + ' ' + addresses.shipping['last_name'] : 'Wurde noch nicht angelegt'}
                                            />
                                            <br />
                                            <br />
                                            <Link to="/konto/addresses/shipping" style={{ color: '#ADADAD' }}>{addresses.shipping !== undefined ? 'Bearbeiten' : 'Hinzufügen'}</Link>
                                        </>
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Addresses
